import { createContext, useState } from "react";

const muser = {
  alumni_id: false,
  alumniun_id: false
}

export const UserContext = createContext(muser);

export const UserProvider = ({children}) => {

  const [user, setUser] = useState(muser);

  // Login updates the user data with a name parameter
  const login = (alumniId) => {
    setUser((user) => ({
      alumni_id: alumniId
    }));
  };

  const loginUn = (alumniun_id) => {
    setUser((user) => ({
      alumniun_id: alumniun_id
    }));
  };

  // Logout updates the user data to default
  const logout = () => {
    setUser((user) => ({
      alumni_id: false
    }));
  };

  return (
    <UserContext.Provider value={{user, login, logout, loginUn}}>
      {children}
    </UserContext.Provider>
  )
}
