import { useEffect, useState } from "react";
// import Blog from "blogs/Sales Process/SalesProcess.html";
function Blogs() {
  return (
    <>
      {/* <div
        dangerouslySetInnerHTML={{
          __html: Blog,
        }}
      /> */}
    </>
  );
}

export default Blogs;
