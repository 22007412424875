// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, addDoc } from "firebase/firestore";
import React, { useState } from "react";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDc3BsSTRGAStdMmbVBsGCYf3q7RF6A4Jg",
  authDomain: "msoftware-m.firebaseapp.com",
  projectId: "msoftware-m",
  storageBucket: "msoftware-m.appspot.com",
  messagingSenderId: "616511325464",
  appId: "1:616511325464:web:807dc9039b3c09222d4a6d",
  measurementId: "G-Y5CWPMQLVC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function Contact() {
  const currentDate = new Date();

  // Get individual components
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = currentDate.getDate();
  const hours = currentDate.getHours();
  const minutes = currentDate.getMinutes();
  const seconds = currentDate.getSeconds();

  // Format the date and time
  const formattedDateTime = `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  } ${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }:${seconds < 10 ? "0" + seconds : seconds}`;

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
    date: formattedDateTime,
  });

  const [result, setResult] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const docRef = await addDoc(collection(db, "contacts"), formData);
      if (docRef) setResult(true);
      // console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      setResult(false);
      console.error("Error adding document: ", e);
    }
  };

  return (
    <>
      <section class="bg-white py-1 md:py-16 dark:bg-zinc-800 dark:text-white">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="space-y-5 max-w-2xl mx-auto text-center">
            <h3 className="text-sky-800 font-semibold">Contact</h3>
            <p className="text-sky-800 text-3xl font-semibold sm:text-4xl">
              Get in touch
            </p>
            <p>We’d love to hear from you! Please fill out the form bellow.</p>
          </div>
          <div className="mt-12 max-w-lg mx-auto">
            {result === false && (
              <form onSubmit={handleSubmit} className="space-y-5">
                <div className="flex flex-col items-center gap-y-5 gap-x-6 [&>*]:w-full sm:flex-row">
                  <div>
                    <label className="font-medium">First name</label>
                    <input
                      type="text"
                      name="firstName"
                      id="firstName"
                      value={formData.firstName}
                      onChange={(e) => handleChange(e)}
                      required
                      className="w-full mt-2 px-3 py-2 text-sky-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                    />
                  </div>
                  <div>
                    <label className="font-medium">Last name</label>
                    <input
                      value={formData.lastName}
                      onChange={(e) => handleChange(e)}
                      name="lastName"
                      id="lastName"
                      type="text"
                      required
                      className="w-full mt-2 px-3 py-2 text-sky-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                    />
                  </div>
                </div>
                <div>
                  <label className="font-medium">Email</label>
                  <input
                    value={formData.email}
                    onChange={(e) => handleChange(e)}
                    name="email"
                    id="email"
                    type="email"
                    required
                    className="w-full mt-2 px-3 py-2 text-sky-500 bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                  />
                </div>
                <div>
                  <label className="font-medium">Phone number</label>
                  <div className="relative mt-2">
                    <input
                      value={formData.phoneNumber}
                      onChange={(e) => handleChange(e)}
                      name="phoneNumber"
                      id="phoneNumber"
                      type="number"
                      required
                      className="w-full pl-2 pr-3 py-2 appearance-none bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                    />
                  </div>
                </div>
                <div>
                  <label className="font-medium">Message</label>
                  <textarea
                    value={formData.message}
                    onChange={(e) => handleChange(e)}
                    name="message"
                    id="message"
                    required
                    className="w-full mt-2 h-36 px-3 py-2 resize-none appearance-none bg-transparent outline-none border focus:border-sky-600 shadow-sm rounded-lg"
                  ></textarea>
                </div>
                <button
                  type="submit"
                  className="w-full px-4 py-3 text-white font-medium bg-sky-700 hover:bg-sky-800 active:bg-sky-900 rounded-full duration-150"
                >
                  Submit
                </button>
              </form>
            )}
            {result === true && (
              <>
                <div className="text-center">
                  <h1>Form Submitted Successfully.</h1>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
