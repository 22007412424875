import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { UserProvider } from "./context/userContext";
import App from "./App";
import Home from "./pages/";
import About from "./pages/about";
import Work from "./pages/work";
import Blogs from "./pages/blogs";
import Contact from "./pages/contact";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="work" element={<Work />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="*"
            element={<h1 style={{ textAlign: "center" }}>404</h1>}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </UserProvider>
);
